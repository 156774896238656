<!-- Menu Start -->
		<div class="navigation-wrap bg-dark start-header start-style">
			<div class="container-xl">
				<div class="row">
					<div class="col-12">
						<nav class="navbar navbar-expand-md">
							<a class="navbar-brand" href="index.html"><img src="assets/dist/images/swisswebart-logo.png" alt="Swisswebart logo"></a>
							<button type="button" class="navbar-toggler {{is_open}}" data-toggle="collapse" data-target="#navbarCollapse" (click)="toggleClick('navbarNavDropdown')">
								<span class="navbar-toggler-icon"></span>
							</button>

							<div class="collapse navbar-collapse"  id="navbarNavDropdown">
								<ul class="navbar-nav ml-auto py-4 py-md-0">
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2 active">
										<a class="nav-link dropdown-toggle" routerLink="/Home" role="button" aria-haspopup="true">{{this.Page_content.menu.Home}}</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2 {{is_show}}" (click)="menuClick('subDropdownMenu')">
										<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{this.Page_content.menu.Services}}<i class="fa fa-angle-down"></i></a>
										<div class="dropdown-menu">
											<a class="dropdown-item" routerLink="/All-in-One">{{this.Page_content.menu.OnlineMarketing}} </a>
											<a class="dropdown-item" routerLink="/Google-Ads">{{this.Page_content.menu.Adwords}} </a>
											<a class="dropdown-item" routerLink="/Webdesign"> {{this.Page_content.menu.Webdesign}} </a>
											<a class="dropdown-item" routerLink="/Seo">{{this.Page_content.menu.SearchEngine}} </a>
											<a class="dropdown-item" routerLink="/Branding-Logo">{{this.Page_content.menu.Branding}}</a>

											
											
											<!-- <a class="dropdown-item" routerLink="/Socialmedia-marketing-zürich">{{this.Page_content.menu.Social}} </a>
											<a class="dropdown-item" routerLink="/Facebook-marketing">{{this.Page_content.menu.Facebook}} </a>
											<a class="dropdown-item" routerLink="/Mobile-app">{{this.Page_content.menu.MobileApp}} </a> -->
										</div>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">

										<a class="nav-link dropdown-toggle" routerLink="/Über-uns" role="button" aria-haspopup="true">{{this.Page_content.menu.Aboutus}}</a>

									</li>
									<!--<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
										<a class="nav-link dropdown-toggle" routerLink="/Blog" role="button" aria-haspopup="true">{{this.Page_content.menu.Blog}}</a>
									</li> -->
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
										<a class="nav-link dropdown-toggle" routerLink="/Kontakt" role="button" aria-haspopup="true">{{this.Page_content.menu.Contactus}}</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
										<a class="nav-link dropdown-toggle" target="_blank" href="https://check.swisswebart.ch/">Analyse</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
										<a class="nav-link dropdown-toggle" target="_blank" href="https://login.swisswebart.ch/">Login</a>
									</li>
									
									<li class="nav-item pl-4 pl-md-0 mt-4 d-block d-sm-block d-md-none">
										<a class="xs-txt" href="mailto:info@swisswebart.ch"><i class="fa fa-envelope"></i> info@swisswebart.ch</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 mt-2 d-block d-sm-block d-md-none">
										<a class="xs-txt" href="tel:438839483"><i class="fa fa-phone"></i> +41 43 883 94 83</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 d-block d-sm-block d-md-none">
										<ul class="social-icons text-left">
											<li class="social-facebook">
												<a href="https://www.facebook.com/webtrade365" target="blank"><i class="fa fa-facebook-square"></i></a>
											</li>
											<li class="social-google-plus">
												<a href="#" target="blank"><i class="fa fa-google-plus-square"></i></a>
											</li>
											<li class="social-instagram">
												<a href="#" target="blank"><i class="fa fa-instagram"></i></a>
											</li>

										</ul>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</div><!-- Menu End -->
