<header>
<app-header-top></app-header-top>
<app-head></app-head>
</header>


	<!-- Banner Start -->
	<div class="banner-section">
		<div class="container-xl">
			<div class="row h-50">
				<div class="col-12 col-md-7 pt-4">
					<!--<img class="d-none d-sm-block img-fluid" [src]="Page_content.BannerSection.imageSrc[0]" alt="Page_content.BannerSection.imageSrc[1]" />
					<img class="d-block d-sm-none img-fluid" [src]="Page_content.BannerSection.imageSrcmobile[0]" alt="Page_content.BannerSection.imageSrcmobile[0]" /> -->
				</div>
				<div class="col-12 col-md-5 pt-4">
					<div class="banner-txt">
						<p class="subtitle text-capitalize text-white">{{Page_content.BannerSection.title}}</p>
						<h3 class="heading-text text-uppercase text-white">{{Page_content.BannerSection.h3}}</h3>
						<!--<div class="border-line"></div>
						<p class="txt text-white" [innerHTML]="Page_content.BannerSection.p"></p>-->
						<a [href]="Page_content.BannerSection.buttonLink" class="btn btn-normal mt-3">{{Page_content.BannerSection.buttonText}} <i [ngClass]="Page_content.BannerSection.buttonIcon"></i></a>
					</div>
				</div>
			</div>
		</div>
	</div><!-- Banner End -->

<!-- Web Agency Start -->
	<div class="agency-section">
		<div class="container-xl">
			<div class="row">
				<div class="col-12 col-md-5">
					<div class="border-line">
						<div class="row">
							<div class="col-4 col-md-3">
								<img class="img-fluid" src="assets/dist/images/globe.svg" alt="Globe" />
							</div>
							<div class="col-8 col-md-8">
								<h1><span class="subtitle span-text text-white">{{Page_content.BannerSection.LogoText}}</span><br/><span class="heading-text text-white">{{Page_content.BannerSection.LogoText1}}</span><br><span class="subtitle span-text text-white">{{Page_content.BannerSection.LogoText2}}</span></h1>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-7">
					<p class="text-white">{{Page_content.BannerSection.BannerText}}</p>
					<!--<p class="text-white mb-0">{{Page_content.BannerSection.BannerText1}}</p> -->
				</div>
			</div>
		</div>
	</div><!-- Web Agency End -->


<app-infoblock-holder [col]="Page_content.Infoblock.col" [content]="Page_content.Infoblock"></app-infoblock-holder>
<app-portfolio [content]="Page_content.Portfolio"></app-portfolio>
	<!-- Logo Carousel Start -->
  <app-flex-width-container [modulesettings]="settings"  [content]="Page_content.BlockSection" ></app-flex-width-container>
  <!-- <app-flex-width-container [modulesettings]="settings1"  [content]="Page_content.BlockSection1" ></app-flex-width-container> -->

	<div class="common-section mt-5">
		<div class="container-xl">
			<div class="row">
				<div class="col-12 col-md-12 mb-5">
          <!-- <owl-carousel
          [options]="{items: 3, dots: false, navigation: false}"
          [items]="images"
          [carouselClasses]="['owl-theme', 'row', 'sliding']">
          <div class="item" *ngFor="let image of images;let i = index">
              <div class="thumbnail-image" [ngStyle]='{"background": "url("abc.jpg")no-repeat scroll center center / 80px 80px"}' ></div>
          </div>
      </owl-carousel> -->
      <!---->
					<div id="logo-carousel">
						<div class="owl-carousel owl-theme">
							<!-- <div class="item"><img src="assets/dist/images/aws-logo.jpg" alt="aws" /></div> -->
							<div class="item"><img src="assets/dist/images/asp-logo.jpg" alt="Aspnet logo" /></div>
							<div class="item"><img src="assets/dist/images/azure-logo.jpg" alt="azure logo" /></div>
							<div class="item"><img src="assets/dist/images/bootstrap-logo.jpg" alt="bootstrap logo" /></div>
							<div class="item"><img src="assets/dist/images/sql-server-logo.png" alt="microsoft sql server logo" /></div>
							<!-- <div class="item"><img src="assets/dist/images/magento-logo.jpg" alt="download logo" /></div>
							<div class="item"><img src="assets/dist/images/crystal-logo.jpg" alt="download1 logo" /></div> -->
							<div class="item"><img src="assets/dist/images/html5_logo1.png" alt="HTML5 logo" /></div>
							<div class="item"><img src="assets/dist/images/mysql-logo.jpg" alt="php_mysql logo" /></div>
							<div class="item"><img src="assets/dist/images/shopify-logo.jpg" alt="Untitled logo" /></div>
							<div class="item"><img src="assets/dist/images/woocommerce-logo.jpg" alt="woocommerce logo" /></div>
							<div class="item"><img src="assets/dist/images/wordpress-logo.jpg" alt="wordpress logo" /></div>
							
						</div>
					</div>

				</div>
			</div>
		</div>
	</div><!-- Logo Carousel End -->
	<!-- Footer Start -->
<footer>
<app-contact-block></app-contact-block>
<app-footer></app-footer>
</footer><!-- Footer End -->
