<!-- Projects Start -->
	<div class="common-section">
		<div class="container-xl">
			<div class="row">
				<div class="col-12 col-md-12 mb-5">
					<h3 class="heading-text text-blue text-uppercase text-center">{{blockcontent.h2}}</h3>
					<p class="subtitle text-black font-weight-bolder text-center mb-1">{{blockcontent.p}}</p>
				</div>
			</div>
			<div class="row">
				<!--<div  class="col-12 col-md-12 text-center">
					<button class="filter-button text-uppercase font-weight-bold" data-filter="hdpe">WEBDESIGN</button>
					<button class="filter-button text-uppercase font-weight-bold" data-filter="sprinkle">BRANDING</button>
					<button class="filter-button text-uppercase font-weight-bold" data-filter="spray">SOCIAL MEDIA</button>
					<button class="filter-button text-uppercase font-weight-bold active" data-filter="all">Alle</button>
				</div> -->
				
				<div class="gallery_product col-12 col-md-4 mb-4 px-4 pb-4 filter hdpe">
					<a href="//tpweddingandevents.ch/" target="_blank"><img src="assets/dist/images/tpweddingandevents-ch1.png" class="img-fluid"></a>
					<!-- <a class="gallery_product-link-txt" href="//tpweddingandevents.ch/" target="_blank">https://tpweddingandevents.ch</a> -->
				</div>
				<div class="gallery_product col-12 col-md-4 mb-4 px-4 pb-4 filter spray">
					<a href="//perruso.ch/" target="_blank"><img src="assets/dist/images/perruso-ch1.png" class="img-fluid"></a>
					<!-- <a class="gallery_product-link-txt" href="//perruso.ch/" target="_blank">https://perruso.ch</a> -->
				</div>
				<div class="gallery_product col-12 col-md-4 mb-4 px-4 pb-4 filter sprinkle">
					<a href="//mastra-bau.ch/" target="_blank"><img src="assets/dist/images/mastra-bau-ch1.png" class="img-fluid"></a>
				</div>
				<div class="gallery_product col-12 col-md-4 mb-4 px-4 pb-4 filter sprinkle">
					<a href="//vmprocolor.ch/" target="_blank"><img src="assets/dist/images/vmprocolor-ch1.png" class="img-fluid"></a>
					<!-- <a class="gallery_product-link-txt" href="//immotrade365.com/home/" target="_blank">https://immotrade365.com</a> -->
				</div>
				<div class="gallery_product col-12 col-md-4 mb-4 px-4 pb-4 filter spray">
					<a href="//sammybauer.ch/" target="_blank"><img src="assets/dist/images/sammybauer-ch1.png" class="img-fluid"></a>
					<!-- <a class="gallery_product-link-txt" href="//sammybauer.ch/" target="_blank">https://sammybauer.ch</a> -->
				</div>
				<div class="gallery_product col-12 col-md-4 mb-4 px-4 pb-4 filter hdpe">
					<a href="//emg-group.ch/" target="_blank"><img src="assets/dist/images/emg-group-ch1.png" class="img-fluid"></a>
					<!-- <a class="gallery_product-link-txt" href="//emg-group.ch/" target="_blank">https://emg-group.ch</a> -->
				</div>

				
			</div>
		</div>
	</div><!-- Projects End -->