<header>
    <app-header-top></app-header-top>
    <app-head></app-head>
    </header>
    
    
    <div class="service-section">
      <div class="container-xl">
        <div class="row">

          <div class="col-12 col-sm-12 col-lg-12 py-5">
            <h3 class="heading-text text-blue text-uppercase pb-3">Coming soon....</h3>
          </div>
          
         
        </div>
      </div>
    </div>

      <!-- Footer Start -->
    <footer>
    <app-contact-block></app-contact-block>
    <app-footer></app-footer>
    </footer><!-- Footer End -->

