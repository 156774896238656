

		<div class="footer-middle">
			<div class="container-xl">
				<div class="row">
					<div class="col pb-4">
						<h5 class="text-white mb-4">Adresse</h5>
						<p>Swisswebart<br>Trade 365 GmbH,<br>Max-Högger-Strasse 6<br>8048 Zürich</p>
						<p>Email: <a href="mailto:info@swisswebart.ch">info@swisswebart.ch</a><br />
						 Phone: <a href="tel:438839483">+41 43 883 94 83</a></p>
					</div>
					<!--<div class="col pb-4">
						<h5 class="text-uppercase text-white mb-4">Opening hours</h5>
						<p>Mon - Fri: 8 AM - 12 PM <br>/ 1 ​​PM - 6 PM</p>
					</div>-->
					<div class="col pb-4">
						<h5 class="text-white mb-4">{{this.Page_content.menu.Services}}</h5>
						<ul class="list-unstyled">
							<li><a routerLink="/All-in-One">{{this.Page_content.menu.OnlineMarketing}} </a></li>
							<li><a routerLink="/Google-Ads">{{this.Page_content.menu.Adwords}}</a></li>
							<li><a routerLink="/Webdesign">{{this.Page_content.menu.Webdesign}}</a></li>
							<!-- <li><a routerLink="#">E-commerce</a></li> -->							
							<li><a routerLink="/Seo">{{this.Page_content.menu.Seo}}</a></li>							
							<li><a routerLink="/Branding-Logo">{{this.Page_content.menu.Branding}}</a></li>
						</ul>
					</div> 
					<div class="col pb-4">
						<!-- <h5 class="text-uppercase text-white mb-4">Terms of use</h5> -->
						<ul class="list-unstyled">
							<li><a routerLink="/Datenschutz">{{this.Page_content.menu.Privacy}}</a></li>
							<li><a routerLink="/Impressum">{{this.Page_content.menu.Imprint}}</a></li>
							<li><a routerLink="/Agb">{{this.Page_content.menu.Agb}}</a></li>
						</ul>
					</div>
					<div class="col pb-4">
						<h5 class="text-white mb-4">Connect</h5>
						<ul class="list-unstyled">
						  <li class="mb-1"><a href="https://www.facebook.com/webtrade365" target="_blank"><i class="fa fa-facebook-square"></i> Facebook</a></li>
						  <li class="mb-1"><a href="#" target="_blank"><i class="fa fa-google-plus-square"></i> Google plus</a></li>
						  <li class="mb-1"><a href="#" target="_blank"><i class="fa fa-instagram"></i> instagram</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container-xl">
				<div class="row">
					<div class="col-12 col-md-12 mb-3 mt-3">
						<p class="text-center mb-0">© 2018 – 2024 Trade 365 GmbH. All rights reserved.</p>
					</div>
				</div>
			</div>
		</div>


