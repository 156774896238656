import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';

import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { HeadComponent } from './Common/head/head.component';
import { FooterComponent } from './Common/footer/footer.component';
import { HeaderTopComponent } from './Common/header-top/header-top.component';
import { ContactBlockComponent } from './Reuseable/contact-block/contact-block.component';
import { InfoblockHolderComponent } from './Reuseable/infoblock-holder/infoblock-holder.component';
import { InfoblockComponent } from './Reuseable/infoblock-holder/infoblock/infoblock.component';
import { FullWidthHolderComponent } from './Reuseable/full-width-holder/full-width-holder.component';
import { ImageblockComponent } from './Reuseable/full-width-holder/imageblock/imageblock.component';
import { TexthtmlComponent } from './Reuseable/full-width-holder/texthtml/texthtml.component';
import { PortfolioComponent } from './Reuseable/portfolio/portfolio.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebDesignComponent } from './web-design/web-design.component';
import { SearchEngineOptimizationComponent } from './search-engine-optimization/search-engine-optimization.component';
import { BrandingComponent } from './branding/branding.component';
import { OnlineMarketingComponent } from './online-marketing/online-marketing.component';
import { GoogleAdwordsComponent } from './google-adwords/google-adwords.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { FacebookMarketingComponent } from './facebook-marketing/facebook-marketing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { FacebookMarketing1Component } from './facebook-marketing1/facebook-marketing1.component';
import { FacebookMarketing2Component } from './facebook-marketing2/facebook-marketing2.component';

import { OwlModule } from 'ngx-owl-carousel';
import { FlexWidthContainerComponent } from './Reuseable/flex-width-container/flex-width-container.component';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { GoogleMapsModule } from '@angular/google-maps';
import { PrivacyComponent } from './privacy/privacy.component';
import { ImprintComponent } from './imprint/imprint.component';
import { BlogComponent } from './blog/blog.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AllInOneComponent } from './all-in-one/all-in-one.component';
import { AgbComponent } from './agb/agb.component';
const appRoutes: Routes = [
  { path: '', redirectTo: '/Home', pathMatch: 'full' },
  {
    path: 'Home',
    component: HomeComponent,
    data:
    {
      title: "Swisswebart – Professionelles Webdesign & Online Marketing Schweiz",
      metatitle: "Swisswebart – Professionelles Webdesign & Online Marketing Schweiz",
      description: "Swisswebart bietet Webdesign, SEO, Google Ads & Online Marketing für Unternehmen in der Schweiz. Jetzt digitale Sichtbarkeit steigern!"
    }
  },
  {
    path: 'Über-uns',
    component: AboutUsComponent,
    data: {
      title: "Über Swisswebart – Experten für Webdesign & Online Marketing",
      metatitle: "Über Swisswebart – Experten für Webdesign & Online Marketing", 
      description: "Swisswebart ist Ihre Agentur für Webdesign, SEO & Google Ads. Erfahren Sie mehr über unsere Mission & Werte. Lassen Sie uns gemeinsam wachsen!"
    }
  },
  { 
    path: 'Socialmedia-marketing-zürich',
    component: SocialMediaComponent, 
    data: 
        { 
          title: "Socialmedia-marketing-zürich", 
          metatitle: "Social Media Marketing Zürich", 
          description:"Social Media Marketing Agentur in Zürich gesucht? Egal ob Strategie, Contentproduktion, Ads oder Workshops. Melden Sie sich bei uns für Top-Leistung!"
        } 
  },
  { 
    path: 'Branding-Logo', component: BrandingComponent, data: { title: "Branding & Logo-Design – Starke Markenidentität mit Swisswebart", metatitle: "Branding & Logo-Design – Starke Markenidentität mit Swisswebart",description:"Professionelles Logo- & Branding-Design für Unternehmen. Swisswebart schafft einzigartige Markenauftritte, die überzeugen! Jetzt beraten lassen." } },
  { path: 'Kontakt', component: ContactUsComponent, data: { title: "Kontakt – Swisswebart | Webdesign & Online Marketing Agentur", metatitle: "Kontakt – Swisswebart | Webdesign & Online Marketing Agentur",description:"Kontaktieren Sie Swisswebart für maßgeschneiderte Webdesign- & Online-Marketing-Lösungen. Jetzt unverbindlich anfragen!"  } },
  { path: 'Facebook-marketing', component: FacebookMarketingComponent, data: { title: "Facebook Marketing in Zürich", metatitle: "Facebook Marketing in Zürich" ,description:"Sie suchen mehr Reichweite, mehr Verkäufe und eine höhere Conversionrate auf Facebook? Dann haben Sie mit ramble bereits den richtigen Partner gefunden!" } },
  { path: 'Facebook-marketing1', component: FacebookMarketing1Component, data: { title: "Facebook-marketing1", metatitle: "meta Facebook-marketing1",description:""  } },
  { path: 'Facebook-marketing2', component: FacebookMarketing2Component, data: { title: "Facebook-marketing2", metatitle: "meta Facebook-marketing2",description:""  } },
  { path: 'Google-Ads', component: GoogleAdwordsComponent, data: { title: "Google Ads Kampagnen – Mehr Kunden durch gezielte Werbung | Swisswebart", metatitle: "Google Ads Kampagnen – Mehr Kunden durch gezielte Werbung | Swisswebart",description:"Mit Swisswebart Google Ads gezielt Neukunden gewinnen. Effektive Kampagnen für mehr Klicks & Conversions. Jetzt kostenlose Beratung sichern!"  } },
  { path: 'Online-marketing', component: OnlineMarketingComponent, data: { title: "Online Marketing in Zürich", metatitle: "Online Marketing in Zürich",description:"Mit gezielten Online Marketing Massnahmen verhelfen wir Ihnen zu mehr Sichtbarkeit im Web und somit zu mehr Erfolg! SEO, SEA, Google Shopping, Video & mehr."  } },
  { path: 'Mobile-app', component: MobileAppComponent, data: { title: "Mobile App entwickeln in Zürich", metatitle: "Mobile App entwickeln in Zürich",description:"Wir entwickeln Mobile Apps für kundenspezifische und komplexe Anwendungsfälle. Programmierung für iOS & Android zu 100% in der Schweiz."  } },
  { path: 'Webdesign', component: WebDesignComponent, data: { title: "Webdesign kreativ umgesetzt", metatitle: "Webdesign kreativ umgesetzt",description:"Wir lieben Webdesign und Websites kreativ umzusetzen. Viele responsive Webdesign und mobile first Webseiten welche unsere Agentur erstellt."  } },
  { path: 'Seo', component: SearchEngineOptimizationComponent, data: { title: "SEO Zürich – Bessere Google Rankings mit Swisswebart", metatitle: "SEO Zürich – Bessere Google Rankings mit Swisswebart",description:"Top-Rankings bei Google mit Swisswebart SEO in Zürich! Wir optimieren Ihre Website für mehr Sichtbarkeit & organischen Traffic. Jetzt anfragen!"  } },
  { path: 'All-in-One', component: AllInOneComponent, data: { title: "Online Listing Service – Mehr Sichtbarkeit für Ihr Unternehmen | Swisswebart", metatitle: "Online Listing Service – Mehr Sichtbarkeit für Ihr Unternehmen | Swisswebart",description:"Mit dem Online Listing Service von Swisswebart optimieren Sie Ihre Firmeneinträge auf Google, Maps & mehr. Jetzt bessere Online-Präsenz sichern!"  } },
  { path: 'Social-media', component: SocialMediaComponent, data: { title: "SEO: jeder will gefunden werden. Einige besser als andere.", metatitle: "SEO: jeder will gefunden werden. Einige besser als andere.",description:"SEO-Projekte unserer Suchmaschinenoptimierungen und Ranking Verbesserungen der Suchresultate von online Plattform bis kleinen Branchen-Websites."  } },
  { path: 'Datenschutz', component: PrivacyComponent, data: { title: "Datenschutz – Ihre Daten bei Swisswebart sind sicher", metatitle: "Datenschutz – Ihre Daten bei Swisswebart sind sicher",description:"Erfahren Sie, wie Swisswebart Ihre Daten schützt. Transparente Datenschutzrichtlinien für sicheres Surfen & Vertrauen. Jetzt informieren!"  } },
  { path: 'Impressum', component: ImprintComponent, data: { title: "Impressum – Swisswebart | Rechtliche Angaben", metatitle: "Impressum – Swisswebart | Rechtliche Angaben",description:"Hier finden Sie die rechtlichen Angaben zur Swisswebart Webdesign & Online Marketing Agentur. Alle Informationen auf einen Blick."  } },
  { path: 'Agb', component: AgbComponent, data: { title: "AGB", metatitle: "AGB",description:"AGB"  } },
  { path: 'Blog', component: BlogComponent, data: { title: "Blog", metatitle: "Blog", description:"meta blog" } }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    HeadComponent,
    FooterComponent,
    HeaderTopComponent,
    ContactBlockComponent,
    InfoblockHolderComponent,
    InfoblockComponent,
    FullWidthHolderComponent,
    ImageblockComponent,
    TexthtmlComponent,
    PortfolioComponent,
    WebDesignComponent,
    SearchEngineOptimizationComponent,
    BrandingComponent,
    OnlineMarketingComponent,
    GoogleAdwordsComponent,
    SocialMediaComponent,
    FacebookMarketingComponent,
    ContactUsComponent,
    MobileAppComponent,
    FacebookMarketing1Component,
    FacebookMarketing2Component,
    FlexWidthContainerComponent,
    PrivacyComponent,
    ImprintComponent,
    BlogComponent,
    AllInOneComponent,
    AgbComponent,
  ],
  imports: [
    GoogleMapsModule,
    BrowserModule,
    NgbModule,
    OwlModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    //  AngularFontAwesomeModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
